import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '@components/layout/Layout'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import SiteLink from '@components/SiteLink'
import ReferenceComponent from '@components/references/reference.component'
import imgAppScreen from '@assets/images/narcolepsy-screening-tools-app.png'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import './swiss-narcolepsy-scale.scss'

const SwissNarcolepsyScale = ({ location }) => {

  const learnMoreContent = {
    className: 'learn-more-wrapper-gray',
    title: 'AASM provides quality measures for managing patients with narcolepsy',
    supText: '2',
    link: '/disease-management/treatment-decisions/',
    linkLabel: 'Discover these approaches',
    tracking: 'SNS Subpage,	click, screener-sns-cta-management considerations',
  }
  const references = [
    {
      content: 'Bassetti CL. Spectrum of narcolepsy. In: Baumann CR, Bassetti CL, Scammell TE, eds. ',
      source: 'Narcolepsy: Pathophysiology, Diagnosis, and Treatment. ',
      info: ' Springer Science+Business Media; 2011:309-319.'
    },
    {
      content: 'Krahn LE, Hershner S, Loeding LD, et al. Quality measures for the care of patients with narcolepsy. ',
      source: 'J Clin Sleep Med. ',
      info: '2015;11(3):335-355.'
    },
    {
      content: 'Sturzenegger C, Bassetti CL. The clinical spectrum of narcolepsy with cataplexy: a reappraisal. ',
      source: 'J Sleep Res. ',
      info: '2004;13(4):395-406.'
    },
    {
      content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In: ',
      source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version. ',
      info: 'Darien, IL: American Academy of Sleep Medicine; 2014.'
    }
  ]
  return (
    <Layout location={location} addedClass="swiss-narcolepsy-scale">
      <div className="primary-container">
        <h1 className="main-page-header"><SiteLink to="/screening-and-diagnosis/screeners/"><FontAwesomeIcon icon={faAngleLeft}/></SiteLink>Swiss Narcolepsy Scale</h1>
        <div className="content-container">
          <p className="page-comment">
            The Swiss Narcolepsy Scale (SNS) is a brief subjective questionnaire that screens for the occurrence of
            several behavioral symptoms that may be associated with narcolepsy with cataplexy.<sup>1</sup>
          </p>
          <div className="learn-more-content">
            <LearnMoreComponent learnMoreContent={learnMoreContent} />
            <p className="aasm-footnote ess-sns-footnote">
              AASM, American Academy of Sleep Medicine.
            </p>
          </div>
        </div>
      </div>
      <div className="feature-wrapper">
        <div className="primary-container">
        <div className="swiss-features-header">
            <h2 className="section-header">Features of the SNS</h2>
            <SiteLink to="/resources/resource-center/" tracking="SNS SubPage, click, sns subpage download tools - clinical resources">
              Download screening tools
            </SiteLink>
          </div>
          <div className="primary-card">
            <div>
              <div className="feature-item">
                <div className="feature-name">Purpose:</div>
                <p className="feature-content">
                  Designed to screen for a symptom profile that might be suggestive of narcolepsy with cataplexy<sup>
                    1
                  </sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Population:</div>
                <p className="feature-content">
                  Patients with excessive daytime sleepiness whom the clinician may want to screen for potential
                  narcolepsy with cataplexy<sup>3</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Assessments:</div>
                <div className="feature-content">
                  <p>
                    Measures frequency of 5 potential symptoms<sup>1</sup>:
                  </p>
                  <div className="measure-item">
                    <p>
                      <b>
                        Q<sup className="sup-bottom">1</sup>
                      </b>
                    </p>&nbsp;<p>–</p>&nbsp;<p>Inability to fall asleep</p>
                  </div>
                  <div className="measure-item">
                    <p>
                      <b>
                        Q<sup className="sup-bottom">2</sup>
                      </b>
                    </p>&nbsp;<p>–</p>&nbsp;<p>Feeling bad or not well rested in the morning</p>
                  </div>
                  <div className="measure-item">
                    <p>
                      <b>
                        Q<sup className="sup-bottom">3</sup>
                      </b>
                    </p>&nbsp;<p>–</p>&nbsp;<p>Taking a nap during the day</p>
                  </div>
                  <div className="measure-item">
                    <p>
                      <b>
                        Q<sup className="sup-bottom">4</sup>
                      </b>
                    </p>&nbsp;<p>–</p>&nbsp;<p>
                      Weak knees/buckling of the knees during emotions such as laughing, happiness, or anger
                    </p>
                  </div>
                  <div className="measure-item">
                    <p>
                      <b>
                        Q<sup className="sup-bottom">5</sup>
                      </b>
                    </p>&nbsp;<p>–</p>&nbsp;<p>
                      Sagging of the jaw during emotions such as laughing, happiness, or anger
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="feature-name">Method:</div>
                <p className="feature-content">
                  Patient self-report<sup>1</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Time required:</div>
                <p className="feature-content">
                  Consists of 5 questions and takes only a few minutes to complete<sup>1</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Scoring:</div>
                <p className="feature-content">
                  Frequency for each behavioral complaint is rated on a 5-point scale, from 1, indicating “never,” to 5,
                  indicating “almost always.” Each question is weighted by a positive or negative factor, with the score
                  calculated using the following equation: (6×Q<sup className="sup-bottom">1</sup> + 9×Q<sup className="sup-bottom">
                    2
                  </sup>{' '}
                  – 5×Q<sup className="sup-bottom">3</sup>
                  – 11×Q<sup className="sup-bottom">4</sup> – 13×Q<sup className="sup-bottom">5</sup> + 20).<sup>
                    1,3
                  </sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Interpretation:</div>
                <p className="feature-content">
                  An SNS score &lt;0 is suggestive of narcolepsy with cataplexy.<sup>1,3</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Validation:</div>
                <p className="feature-content">
                  In 1 study of patients with narcolepsy with cataplexy, an SNS score &lt;0 was shown to have a
                  sensitivity of 96% and specificity of 98%.<sup>3</sup>
                </p>
              </div>
            </div>
            <p className="feature-comment">
              A narcolepsy diagnosis should be established by a sleep specialist using a clinical interview and a
              nighttime polysomnogram (PSG) followed by a multiple sleep latency test (MSLT) performed under
              standardized conditions.<sup style={{fontStyle: 'normal'}}>4</sup>
            </p>
          </div>
        </div>
      </div>
      <div className="primary-container">
        <div className="app-comment">
          The Swiss Narcolepsy Scale is copyrighted and reproduced with permission of the authors. Unauthorized copying,
          printing or distribution is strictly prohibited.
        </div>
      </div>
      <ReferenceComponent referenceList={references} />
    </Layout>
  )
}

export default SwissNarcolepsyScale
